//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

export default {
  name: "SignHistoryCard",
  props: ['signHistory'],
  data() {
    return {
      collapseVisible: false,
      history: null,
      format: "YYYY-MM-DD HH:mm:ss.SSSSSS",
      localFormat: "DD.MM.YYYY HH:mm:ss",
    }
  },
  created() {
    const history = [];
    const signHistory = Object.values(this.signHistory) || [];
    
    signHistory.forEach(signType => 
      signType.forEach( sign => { 
        history.push({
          date: moment(sign.date.date, this.format).format(this.localFormat),
          status: sign.eventType, 
          title: `${sign.eventTypeTranslated}`
        });
      }
    ));

    if (history.length > 1) {
      history.sort((prev, next) => {
        return moment(next.date, this.localFormat)
          .diff(moment(prev.date, this.localFormat), 'seconds');
      })
    }
    this.history = history;
  }
}
