//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SignerHistory from "./SignerHistory.vue";

export default {
  name: "SignHistory",
  components: { SignerHistory },
  props: {
    document: Object,
  },
  data() {
    return {
      signHistory: {},
      signColorStatus: '',
    }
  },
  created() {
    
    const signersHistory = this.document.signers.map(((signer) => {
      if (this.document.signHistory && this.document.signHistory[signer.workerId]) {
        return { ...signer, signHistory: this.document.signHistory[signer.workerId] };
      } else {
        const newProp = {
          comment: null,
          date: {
            date: '',
          },
          eventType: 'unprocess',
          eventTypeTranslated: 'Не подписано',
          signType: 'not_signed',
          signTypeTranslated: 'Не подписано',
          userFIO: `${signer.surName} ${signer.firstName} ${signer.middleName}`,
          userID: signer.workerId,
          worker: {
            company: signer.sign && signer.sign.company || '-',
            department: signer.sub || '-',
            fio: `${signer.surName} ${signer.firstName} ${signer.middleName}`,
            position: signer.pos || '-',
            subdivision: signer.sub || '-',
            workerId: signer.workerId || '-',
          }
        }
        return { 
          ...signer,
          signHistory: { 'not_signed' : [newProp] },
        }
      }
    }));
    this.$set(this.document, 'signers', signersHistory);
  },
  mounted() {
    this.getColorStatus(history.progress);
  },
  methods: {
    getColorStatus(progress) {
      if (progress === 0) this.signColorStatus = 'notSigned';
      if (progress === 100) this.signColorStatus = 'fullySigned';
      if (progress !== 100 && progress !== 0) this.signColorStatus = 'partiallySigned';
    }
  },
  computed: {
    calculateSignPercent() {
      if (this.document && this.document.dsSignersCount > 0) {
        return Math.floor(this.document.dsProcessedByCount / this.document.dsSignersCount * 100);
      }
      return 0;
    },
  }
}
