//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/api/api";
import $ from "jquery";
import { mapMutations } from 'vuex';

import notification from "@/notification/notification";
import Loader from '../../../main/Loader.vue';
import SignCard from "@/components/documents/table/SignCard";
import TwoUsersIcon from "@/components/icons/TwoUsersIcon";
import MinTrud from "@/components/icons/MinTrud";

import socket from "@/ws/socket";

const headers = [
  {
    key: 'index',
    label: '',
  },
  {
    key: 'date',
    label: 'Дата',
    sortable: true,
  },
  {
    key: 'docId',
    label: 'Номер',
  },
  { 
    key: 'name',
    label: 'Тип',
    tdClass: 'alignStart',
  },
  {
    key: 'signed',
    label: 'Подпись'
  }
];

export default {
  name: "ExternalDocumentsTable",
  components: { TwoUsersIcon, Loader, MinTrud, SignCard },
  props: {
    documents: Array,
    total: Number,
    getDocuments: Function,
    changeDocument: Function,
  },
  data() {
    return {
      headers: headers,
      headersMobile: [headers[0], headers[1]],
      isDocumentsChecked: false,
      signRules: {},
      ecSignRules: {},

      limit: 25,
      pageNumber: 1,
      limitChoices: [
        25, 50, 100
      ],
    }
  },
  mounted() {
    this.getSignRules();
    if (!this.$store.state.isCentriConnected) socket.connect();
  },
  methods: {
    handleSelectDocs(document, state) {
      document.checked = state;
      if (this.isDocumentsChecked && !state) {
        this.isDocumentsChecked = false;
      }
      else {
        if (state) {
          this.isDocumentsChecked = !this.documents.some((document) => {
            if (document.needRegNum && !document.regNum) return false;
            return !document.checked;
          })
        }
      }
    },
    isChecked(id) {
      return this.selectedDocuments.includes(id);
    },
    couldBeSigned(document) {
      if(!document.regNum && document.needRegNum) return false;
      return true;
    },
    countScans(scans) {
      return scans.length
    },
    getPercent(document) {
      return `${this.$t('external_education.signed')} ${(document.dsProcessedByCount / document.dsSignersCount) * 100}%`;
    },
    getSignedClass(document) {
      if(!document.dsProcessedByCount) return 'dangerCount';
      if (document.dsProcessedByCount === document.dsSignersCount) return 'warningCount';
      return 'successCount';
    },
    viewDocument() {
      const response = api.getDocumentsPreview(this.selectedDocuments.map(document => document.id));
      response.then(res => {
        if (!res.data.result || !res.data.result.previewLink) {
          return
        }
        window.location.href = res.data.result.previewLink;
      })
    },    
    showGetArchiveModalSelected() {
      this.setIdDocuments(this.selectedDocuments.map(document => document.id));
      $('#modalGetArchive').modal('show');
      $('#modalGetArchive').on('hidden.bs.modal', () => {
        $('body').addClass('modal-open');
      })
    },
    getDocumentDate(date, factDate) {
      if (factDate == "") {
        return date;
      }
      return factDate;
    },
    showScansPreviews(docs) {
      this.$router.push({
        name: 'document-view',
        query: {
          type: "external_education",
        },
        params: {
          docs,
        }
      })
    },
    getSignRules() {
      this.setExternalDocumentLoading(true);
      // const response = api.getSignRulesForType([]);
      const response = Promise.all([api.getSignRulesForType([]), api.getSignRulesForEcDocsType([])]);
      response.then(([res, resEc]) => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login',
          })
        } else if (res.data.error && res.data.errorStr) {
          notification.error(res.data.errorStr);
        } else {
          this.signRules = res.data.result.rules;
        }
        if (resEc.data.error && resEc.data.error === 401) {
          this.$router.push({
            name: 'login',
          })
        } else if (resEc.data.error && resEc.data.errorStr) {
          notification.error(resEc.data.errorStr);
        } else {
          this.ecSignRules = resEc.data.result.rules || { DEFAULT: true, RULES: resEc.data.result.default_rules };
          if (resEc.data.result.rules && resEc.data.result.default_rules) {
            this.ecSignRules.unshift({ DEFAULT: true, RULES: resEc.data.result.default_rules });
          }
        }
      }).finally(() => {
        this.setExternalDocumentLoading(false);
      }).catch((e) => {
        console.log(e);
      })
    },
    isCurrentPage(number) {
      if (this.pageNumber == number) {
        return true;
      }
      return false;
    },
    setPageNumber(number) {
      this.pageNumber = number;
    },
    handleDocumentSelect(...props) {
      this.pageNumber = 1;
      this.isDocumentsChecked = false;
      this.getDocuments(...props)
    },
    handleCheckAllDocuments(){
      this.isDocumentsChecked = !this.isDocumentsChecked;
      this.documents.forEach((document) => {
        if (!(document.needRegNum && !document.regNum))
          document.checked = this.isDocumentsChecked;
      })
    },
    ...mapMutations([
      'setExternalDocumentLoading',
      'setIdDocuments'
    ])
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total/this.limit);
    },
    selectedDocuments(){
      return this.documents.filter(document => document.checked);
    },
  }
}
